<template>
  <div v-title data-title="Care for our Energy - A Step to Green">
    <div v-if="offsetwidth > 850">
      <img style="width: 100%" src="../assets/img/contactUs.jpg" alt="" />
      <div style="position: relative">
        <img style="width: 100%" src="../assets/img/white.jpg" alt="" />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 50%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/img/transparentBtn.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/img/aboutUs.jpg" alt="" />
    </div>
    <div v-else style="width: 100%">
      <img
        style="width: 100%"
        src="../assets/img/contactUs-A手机版.jpg"
        alt=""
      />
      <div style="position: relative">
        <img style="width: 100%" src="../assets/img/phone/white.jpg" alt="" />

        <img
          style="
            width: 45%;
            position: absolute;
            top: 50%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/img/transparentBtn.png"
          alt=""
        />
      </div>
      <img
        style="width: 100%"
        src="../assets/img/phone/phoneAboutUs.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    window.fbq("track", "ViewContent", {
      content_type: "Contact Us",
      content_ids: ["10005"],
      content_name: "Contact Us",
      content_category: "Contact Us",
    });
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style></style>
