<template>
  <div v-title data-title="Care for our Energy - A Step to Green">
    <div v-if="offsetwidth > 850"  style="
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1300px;
      ">
      <img style="width: 100%" src="../assets/imgTwo/PC/top.jpg" alt="" />
      <img style="width: 100%" src="../assets/imgTwo/PC/green.jpg" alt="" />
       <img style="width: 100%" src="../assets/imgTwo/PC/our_product.jpg" alt=""/>
      <div style="display: flex; width: 100%; justify-content: center">
        <img style="width: 100%" src="../assets/imgTwo/PC/white.jpg" alt=""/>
          <img style="width: 100%" src="../assets/imgTwo/PC/pro_mac.jpg" alt=""   @click="jump('mac')"/>
            
            <img style="width: 100%" src="../assets/imgTwo/PC/pro_sp.jpg" alt=""    @click="jump('sp')"/>
      <img style="width: 100%" src="../assets/imgTwo/PC/white.jpg" alt=""/>
      </div>
      <!-- <img style="width: 100%" src="../assets/imgTwo/PC/introduce.jpg" alt="" /> -->
      <img style="width: 100%" src="../assets/imgTwo/PC/house.jpg" alt="" />
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgTwo/PC/join-now.jpg"
          alt=""
        />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 70%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgTwo/PC/Btn.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgTwo/PC/aboutus.jpg" alt="" />
    </div>
    <div v-else style="width: 100%">
      <img style="width: 100%" src="../assets/imgTwo/H5/top.png" alt="" />
      <img style="width: 100%" src="../assets/imgTwo/H5/green.png" alt="" />
      <img style="width: 100%" src="../assets/imgTwo/H5/ProOne.png" alt=""  @click="jump('mac')"/>
      <img style="width: 100%" src="../assets/imgTwo/H5/ProThree.png" alt="" @click="jump('sp')"/>
      <!-- <img style="width: 100%" src="../assets/imgTwo/H5/ProFour.jpg" alt="" /> -->
      <img style="width: 100%" src="../assets/imgTwo/H5/house.png" alt="" />
      <div style="position: relative">
        <img style="width: 100%" src="../assets/imgTwo/H5/join-in.jpg" alt="" />

        <img
          style="
            width: 45%;
            position: absolute;
            top: 70%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgTwo/H5/Btn.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgTwo/H5/aboutus.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    window.fbq("track", "ViewContent", {
      content_type: "index",
      content_ids: ["10001"],
      content_name: "index",
      content_category: "index",
    });
  },
  methods: {
     jump(tag) {
      if (tag == "sp") {
        this.$router.push({
          path: "/SolarPanelSystem",
        });
      } else if (tag == "home") {
        this.$router.push({
          path: "/HomeEssential",
        });
      } else if (tag == "mac") {
        this.$router.push({
          path: "/MAC",
        });
      }
    },
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style></style>
