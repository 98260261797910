<template>
  <div v-title data-title="Privacy Policy">
    <div
      v-if="offsetwidth > 850"
      class="flex flex_around"
      style="margin-bottom: 20px"
    >
      <div
        style="
          width: 75%;
          min-width: 240px;
          max-width: 800px;
          margin-top: 50px;
          backround: #eee;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 10px 25px;
        "
      >
        <h2 class="font_bold">TERMS AND CONDITIONS FOR ASTEP TO GREEN FACEBOOK/INSTAGRAM ADS</h2>

        <div class="text_left font_15">
          <div>
            <p>
              These terms and conditions ("Terms") govern your use of and interaction with the
advertisements ("Ads") displayed by Astep to Green on Facebook. By engaging with our Ads or accessing our services, you agree to comply with and be bound by these Terms. Please read them carefully.
            </p>
            
            <ol>
              <li style="margin-top:10px">Advertisements: Astep to Green may display Ads on Facebook/Instagram to promote its products, services, or events. The content, format, and duration of the Ads are subject to change without notice.</li>
              <li  style="margin-top:10px">
               Advertiser's Responsibility: Astep to Green strives to provide accurate and up- to-date information in its Ads. However, we do not guarantee the completeness, accuracy, or reliability of the information. It is your responsibility to verify the
information presented in the Ads before making any decisions or taking any actions based on them.
              </li>
              <li  style="margin-top:10px">
                Targeted Audience: The Ads may be targeted to specific audiences based on various factors, such as demographics, interests, or location. The targeting criteria are
determined solely by Astep to Green and are subject to change at any time.
              </li>
               <li  style="margin-top:10px">
               Third-Party Websites: The Ads may contain links to third-party websites or resources. Astep to Green does not endorse or assume any responsibility for the content, products, or services provided by such third parties. You acknowledge that your
interactions with third-party websites or resources are solely at your own risk.
              </li>
               <li  style="margin-top:10px">
               Intellectual Property: The Ads, including but not limited to text, images, logos,
trademarks, and videos, are the intellectual property of Astep to Green or its licensors. You may not modify, reproduce, distribute, or create derivative works based
on the Ads without obtaining explicit written permission from Astep to Green Victoria.
              </li>
              <li  style="margin-top:10px">
               Data Collection and Privacy: By interacting with our Ads, you acknowledge and agree
that Astep to Green may collect and process your personal data in accordance
with our Privacy Policy. Please refer to our Privacy Policy for detailed information on how we handle and protect your personal information.
              </li>
                 <li  style="margin-top:10px">
            Limitation of Liability: To the extent permitted by applicable law, Astep to Green shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from or related to the Ads, including but not limited to errors,
omissions, or inaccuracies in the content.
              </li>
                <li  style="margin-top:10px">
           Modifications: Astep to Green reserves the right to modify or discontinue the Ads at any time without prior notice. We may also update these Terms periodically, and any changes will be effective immediately upon posting. It is your responsibility to
review these Terms regularly to stay informed of any updates.
              </li>
                <li  style="margin-top:10px">
          Governing Law: These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where Astep to Green operates, without regard to its conflict of law provisions.
              </li>
                <li  style="margin-top:10px">
        Contact: If you have any questions or concerns about these Terms or our Ads, please contact us at (03) 8528 1831.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex_around" style="margin-bottom: 20px">
      <div
        style="
          width: 90%;
          min-width: 240px;
          max-width: 800px;
          margin-top: 10px;
          backround: #eee;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 10px 15px;
        "
      >
         <h2 class="font_bold">TERMS AND CONDITIONS FOR ASTEP TO GREEN FACEBOOK/INSTAGRAM ADS</h2>

        <div class="text_left font_15">
          <div>
            <p>
              These terms and conditions ("Terms") govern your use of and interaction with the
advertisements ("Ads") displayed by Astep to Green on Facebook. By engaging with our Ads or accessing our services, you agree to comply with and be bound by these Terms. Please read them carefully.
            </p>
            
            <ol>
              <li style="margin-top:10px">Advertisements: Astep to Green may display Ads on Facebook/Instagram to promote its products, services, or events. The content, format, and duration of the Ads are subject to change without notice.</li>
              <li  style="margin-top:10px">
               Advertiser's Responsibility: Astep to Green strives to provide accurate and up- to-date information in its Ads. However, we do not guarantee the completeness, accuracy, or reliability of the information. It is your responsibility to verify the
information presented in the Ads before making any decisions or taking any actions based on them.
              </li>
              <li  style="margin-top:10px">
                Targeted Audience: The Ads may be targeted to specific audiences based on various factors, such as demographics, interests, or location. The targeting criteria are
determined solely by Astep to Green and are subject to change at any time.
              </li>
               <li  style="margin-top:10px">
               Third-Party Websites: The Ads may contain links to third-party websites or resources. Astep to Green does not endorse or assume any responsibility for the content, products, or services provided by such third parties. You acknowledge that your
interactions with third-party websites or resources are solely at your own risk.
              </li>
               <li  style="margin-top:10px">
               Intellectual Property: The Ads, including but not limited to text, images, logos,
trademarks, and videos, are the intellectual property of Astep to Green or its licensors. You may not modify, reproduce, distribute, or create derivative works based
on the Ads without obtaining explicit written permission from Astep to Green Victoria.
              </li>
              <li  style="margin-top:10px">
               Data Collection and Privacy: By interacting with our Ads, you acknowledge and agree
that Astep to Green may collect and process your personal data in accordance
with our Privacy Policy. Please refer to our Privacy Policy for detailed information on how we handle and protect your personal information.
              </li>
                 <li  style="margin-top:10px">
            Limitation of Liability: To the extent permitted by applicable law, Astep to Green shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from or related to the Ads, including but not limited to errors,
omissions, or inaccuracies in the content.
              </li>
                <li  style="margin-top:10px">
           Modifications: Astep to Green reserves the right to modify or discontinue the Ads at any time without prior notice. We may also update these Terms periodically, and any changes will be effective immediately upon posting. It is your responsibility to
review these Terms regularly to stay informed of any updates.
              </li>
                <li  style="margin-top:10px">
          Governing Law: These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where Astep to Green operates, without regard to its conflict of law provisions.
              </li>
                <li  style="margin-top:10px">
        Contact: If you have any questions or concerns about these Terms or our Ads, please contact us at (03) 8528 1831.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
};
</script>

<style>
.font_15 {
  font-size: 15px;
}
.font_16 {
  font-size: 16px;
}
.text_bold {
  font-weight: 700;
}
.text_left {
  text-align: left;
}
.text_center {
  text-align: center;
}
</style>
<style scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
.flex {
  display: flex;
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
</style>
