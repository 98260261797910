<template>
  <div
    class="home1"
    v-title
    data-title="Solar"
    :style="{ fontSize: postFontSize + 'px' }"
  >
    <div id="all">
      <el-form
        class="home_form"
        ref="form"
        :label-position="tablewidth >= 700 ? 'left' : 'top'"
        :model="form"
        label-width="150px"
        :rules="rules"
      >
        <div
          style="
            text-align: left;
            border-radius-left-top: 10px;
            border-radius-right-top: 10px;
          "
        >
          <!-- <el-button @click="add">A+</el-button>
           <el-button @click="reduce">A-</el-button> -->
          <!-- <div class="bor_green"></div> -->
          <div
            class="home_form_item"
            style="background: rgb(239 240 241); line-height: 30px"
          >
            <div style="text_align: left">
              <div
                style="font-weight: 600; font-size: 17px; padding: 10px 20px"
              >
                <span>Customer Information</span>
              </div>
              <!-- <div style="margin-right: 10px; color: #409eff">
                <span @click="uplg(0)">EN</span>/<span @click="uplg(1)"
                  >中文</span
                >
              </div> -->
            </div>
          </div>
          <el-form-item
            style="padding-top: 30px"
            class="home_form_item"
            label="First Name*"
            prop="FirstName"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              clearable
              v-model="form.FirstName"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item
            class="home_form_item"
            label="Last Name* "
            prop="LastName"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              clearable
              v-model="form.LastName"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item
            class="home_form_item"
            label="Phone*"
            prop="Phone"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              clearable
              v-model="form.Phone"
            ></el-input>
          </el-form-item>
        </div>
        <!-- <div style="text-align: left"></div> -->

        <el-form-item
          class="home_form_item"
          label="Email Address"
          prop="Emailaddress"
          align="left"
        >
          <text class="line line2"></text>
          <el-input
            class="home_input"
            auto-complete="new-accounts"
            clearable
            v-model="form.Emailaddress"
          ></el-input>
        </el-form-item>

        <!-- 地图地址 -->
        <div style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item
            class="home_form_item"
            for="title"
            label="Installation Address"
            prop="address"
            align="left"
          >
            <!-- <text class="line line2"></text> -->
            <div
              style="
                margin-left: 10px;
                text-align: right;
                transform: translateY(-10px);
              "
            >
              <i
                @click="showhide"
                :class="
                  showandhide
                    ? 'el-icon-circle-close'
                    : 'el-icon-circle-plus-outline'
                "
              ></i>
            </div>
            <div style="transform: translateY(-10px)" class="flex flex_between">
              <div class="home_input">
                <el-input
                  clearable
                  v-model="form.address"
                  name="autocomplete"
                  id="autocomplete"
                  autocomplete="off"
                  @focus="geolocate()"
                  required
                ></el-input>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-show="showandhide" style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item class="home_form_item" label="Unit Number" align="left">
            <text class="line line2"></text>
            <el-input
              class="home_input"
              autocomplete="off"
              clearable
              v-model="form.subpremise"
              id="subpremise"
            ></el-input>
          </el-form-item>
        </div>
        <div v-show="showandhide" style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item
            class="home_form_item"
            for="title"
            label="Street Number*"
            prop="street_number"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              name="streetNumber"
              id="street_number"
              autocomplete="off"
              required
              v-model="form.street_number"
            ></el-input>
          </el-form-item>
        </div>
        <div v-show="showandhide" style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item
            class="home_form_item"
            for="title"
            label="Street Name*"
            prop="route"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              name="streetName"
              id="route"
              autocomplete="off"
              required
              v-model="form.route"
            ></el-input>
          </el-form-item>
        </div>
        <div v-show="showandhide" style="text-align: left">
          <!-- <div class="bor_green"></div> -->

          <el-form-item
            class="home_form_item"
            for="title"
            label="Suburb*"
            prop="locality"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              name="locality"
              id="locality"
              autocomplete="off"
              required
              v-model="form.locality"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: left">
          <!-- <div class="bor_green"></div> -->
          <el-form-item
            v-show="showandhide"
            class="home_form_item"
            for="title"
            label="Postcode*"
            prop="postal_code"
            align="left"
          >
            <text class="line line2"></text>
            <el-input
              class="home_input"
              name="postcode"
              id="postal_code"
              autocomplete="off"
              required
              v-model="form.postal_code"
            ></el-input>
          </el-form-item>
        </div>

        <!-- product -->
        <div class="home_form_item">
          <el-form-item style="padding-bottom: 10px; text-align: left">
            <!-- <div>
              <span style="font-weight: 600; font-size: 17px"
                >Please Select Products</span
              >
              <div>
                (Choose products which have not already been installed under
                Victorian Energy Upgrades Program)
              </div>
            </div> -->
            <div style="margin-top: 20px">
              <!-- 第三行 -->
              <div style="margin-top: 5px" class="flex_around">
                <!-- SP -->
                <div class="iconparent">
                  <div class="icon_div">
                    <img class="icon" src="../assets/img/solar.png" alt="" />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </el-form-item>

          <div class="home_form_item1">
            <div style="margin-top: 10px; padding: 0 25px; text-align: center">
              <!-- <el-checkbox id="checkbox" class="flex" v-model="form.radio">
                You acknowledge that you must provide your recent electricity
                bill to the installer upon arrival.
              </el-checkbox> -->
              <span style="font-size: 12px; font-weight: 600"
                >Submit Your Information for FREE Consultation</span
              >
              <el-button
                :disabled="submitbtn"
                style="margin: 10px 0"
                type="primary"
                @click="onSubmit('form')"
                >Submit</el-button
              >
            </div>
          </div>
        </div>
      </el-form>

      <GMaps
        v-show="false"
        class="Gmaps"
        @fun="getinfo"
        ref="child"
        :map-data="mapData"
      />
    </div>
  </div>
</template>

<script>
import GMaps from "@/components/map/Gmaps";
import { get, post } from "../assets/api/api";
export default {
  components: { GMaps },
  data() {
    const notNull = (rule, value, callback) => {
      // console.log(value);
      if (!value) {
        return callback(new Error(`${rule.field} is required `));
      }
      // let reg = /^[A-Za-z0-9,.;?@/s]+$/;
      //   if (value && !reg.test(value)) {
      //     return callback(new Error(`${rule.field} is required `));
      //   }
      // (!(/([\u4e00-\u9fa5]{2,4})/.test(value)) &&
      // /^[?=\x21-\x7eA-Za-z0-9]+[?=\x21-\x7eA-Za-z0-9\s]*[?=\x21-\x7eA-Za-z0-9]+$/.test(value))
      callback();
    };
    const notNull1 = (rule, value, callback) => {
      // console.log(value);
      if (
        !this.form.route ||
        !this.form.locality ||
        !this.form.street_number ||
        !this.form.postal_code
      ) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const notNullallofaddress = (rule, value, callback) => {
      if (
        !this.form.route &&
        !this.form.locality &&
        !this.form.street_number &&
        !this.form.postal_code &&
        !value
      ) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const checkpostcode1 = (rule, value, callback) => {
      let reg = /^3[0-9]{3}$/;
      if (!reg.test(this.form.postal_code)) {
        return callback(new Error(`${rule.field} is required `));
      }

      callback();
    };
    const checkphone = (rule, value, callback) => {
      // console.log(value);
      let reg = /^0[3,4][0-9]{8}$/;
      if (!reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }
      if (!value) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const checkpostcode = (rule, value, callback) => {
      let reg = /^3[0-9]{3}$/;
      // console.log(reg.test(value));

      if (!reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }

      callback();
    };

    const checkemail = (rule, value, callback) => {
      let reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (value && !reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    return {
      showandhide: false,
      tablewidth: document.documentElement.clientWidth,
      font: "",
      To_Store_ID: 80,
      ToSalerID: 20648,
      lg: 0, //语言
      postFontSize: "16",
      mapData: {
        latitude: 33.242,
        longitude: 124.12,
      },
      autocomplete: null,
      companyauto: null,
      submitbtn: false,
      checkList: [],
      form: {
        radio: false,
        language: "English",
        address: "",
        subpremise: "",
        street_number: "",
        route: "",
        locality: "",
        City: "",
        postal_code: "",
        // ToSalesID: "",
        // Dimmer: "",
        // LEDNumber: "",
        // cell_height: "",
        // SHNumber: "",
      },
      show_language: false,
      // opt_sh: [
      //   {
      //     name: "1",
      //     label: "1",
      //   },
      //   {
      //     name: "2",
      //     label: "2",
      //   },
      //   {
      //     name: "3",
      //     label: "3",
      //   },
      //   {
      //     name: "3+",
      //     label: "3+",
      //   },
      // ],
      // opt_dimme: [
      //   {
      //     name: "yes",
      //     label: "yes",
      //   },
      //   {
      //     name: "no",
      //     label: "no",
      //   },
      // ],
      // opt_height: [
      //   {
      //     name: "2m-3m",
      //     label: "2m-3m",
      //   },
      //   {
      //     name: "3m-4m",
      //     label: "3m-4m",
      //   },
      //   {
      //     name: "4m+",
      //     label: "4m+",
      //   },
      // ],
      opt_number: [
        {
          name: "1",
          label: "1",
        },
        {
          name: "2",
          label: "2",
        },
        {
          name: "3",
          label: "3",
        },
        {
          name: "3+",
          label: "3+",
        },
      ],
      opt_sales: [],
      languageArr: [
        {
          name: "English",
          label: "English",
        },
        {
          name: "Chinese",
          label: "Chinese",
        },
        {
          name: "Vietnamese",
          label: "Vietnamese",
        },
      ],
      rules: {
        address: [
          {
            validator: notNullallofaddress,
            message: "Please Enter Your Address.",
            trigger: ["blur"],
          },
          {
            validator: notNull1,
            message:
              "Please Check Address and Re-enter.  (Enter + Sign for Manual Address Entry)",
            trigger: ["blur"],
          },
          {
            validator: checkpostcode1,
            message: "Your Address Must be in Victoria.",
            trigger: ["change", "blur"],
          },
        ],
        FirstName: [
          {
            validator: notNull,
            message: "Please Enter First Name",
            trigger: ["change", "blur"],
          },
        ],
        LastName: [
          {
            validator: notNull,
            message: "Please Enter Last Name",
            trigger: ["change", "blur"],
          },
        ],
        Phone: [
          {
            validator: notNull,
            len: 10,
            message: "Please Enter Your Mobile Number",
            trigger: ["change", "blur"],
          },
          {
            validator: checkphone,
            // pattern: /^0[3,4][0-9]{8}$/,
            len: 10,
            message: "Please Enter Your Correct Mobile Number",
            trigger: ["change", "blur"],
          },
        ],
        Emailaddress: [
          {
            validator: notNull,

            message: "Please Enter Your Email Address",
            trigger: ["change", "blur"],
          },
          {
            validator: checkemail,

            message: "Please Enter Your Correct Email Address",
            trigger: ["change", "blur"],
          },
        ],

        street_number: [
          {
            validator: notNull,
            message: "Please Enter Your Street Number",
            trigger: ["change", "blur"],
          },
        ],
        route: [
          {
            validator: notNull,
            message: "Please Enter Your Street Name",
            trigger: ["change", "blur"],
          },
        ],
        locality: [
          {
            validator: notNull,
            message: "Please Enter Your Suburb",
            trigger: ["change", "blur"],
          },
        ],
        postal_code: [
          {
            validator: notNull,
            // pattern: /^3[0-9]{3}$/,
            message: "Please Enter Your Post Code",
            trigger: ["change", "blur"],
          },
          {
            validator: checkpostcode,
            // pattern: /^3[0-9]{3}$/,
            message: "Your Address Must be in Victoria.",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler: function (newV, oldV) {
        // console.log(this.form);
        if (newV.FreeLED === 1) {
          oldV.Dimmer = null;
          oldV.LEDNumber = null;
          oldV.cell_height = null;
        }
      },
    },
    tablewidth: function (val) {
      if (!this.timer) {
        this.tablewidth = val;
        // console.log(val)
        // this.changeheight(val);
        this.timer = true;
        let that = this;
        setTimeout(function () {
          //频繁触发 resize 函数，会导致页面很卡
          that.timer = false;
        }, 100);
      }
    },
  },
  mounted() {
    // this.To_Store_ID = this.$route.query.To_Store_ID;
    // this.ToSalerID = this.$route.query.ToSalerID;
    this.getsale();
    // fbq('init', '319494653477175');
    // console.log(window);
    // window.fbq("track", "ViewContent", {
    //   content_type: "CustomerSolarDetails",
    //   content_ids: ["10003"],
    //   content_name: "CustomerSolarDetails",
    //   content_category: "CustomerSolarDetails",
    // });
    let that = this;
    window.onresize = () => {
      return (() => {
        window.tablewidth = document.documentElement.clientWidth;
        that.tablewidth = window.tablewidth;
      })();
    };
  },
  methods: {
    showhide() {
      this.showandhide = !this.showandhide;
    },
    selectpro(type) {
      let res =
        this.form.checkList.filter((item) => {
          return item === type;
        }).length > 0;
      if (res) {
        this.form.checkList.splice(this.form.checkList.indexOf(type), 1);
      } else {
        this.form.checkList.push(type);
      }
    },

    //获取销售下拉
    getsale() {
      get(
        "/api/api/QR_CustomerOrders/GetTodayClockSales?ToStoreID=" +
          this.To_Store_ID
      ).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.opt_sales = res.data.data;
        }
      });
    },

    // uplg(type) {
    //   // console.log(type);
    //   this.lg = type;
    //   if (this.lg === 0) {
    //     this.languageArr = [
    //       {
    //         name: "English",
    //         label: "English",
    //       },
    //       {
    //         name: "Chinese",
    //         label: "Chinese",
    //       },
    //       {
    //         name: "Vietnamese",
    //         label: "Vietnamese",
    //       },
    //     ];
    //   } else {
    //     this.languageArr = [
    //       {
    //         name: "English",
    //         label: "英文",
    //       },
    //       {
    //         name: "Chinese",
    //         label: "中文",
    //       },
    //       {
    //         name: "Vietnamese",
    //         label: "Vietnamese",
    //       },
    //     ];
    //   }
    // },

    onSubmit() {
      // if(this.form.checkList.){}

      let data = {
        To_Store_ID: this.To_Store_ID,
        FirstName: this.form.FirstName,
        LastName: this.form.LastName,
        Phone: this.form.Phone,
        Emailaddress: this.form.Emailaddress,
        Address: this.form.address,
        Unit_Number: this.form.subpremise,
        Street_Number: this.form.street_number,
        Street_Name: this.form.route,
        Suburb: this.form.locality,
        City: this.form.City,
        PostCode: this.form.postal_code,
        Language: this.form.language,
        ToSalesID: this.ToSalerID,
        Pro_SH: 0,
        Pro_IHD: 0,
        Pro_DS: 0,
        Pro_CS: 0,
        Pro_LED: 0,
        Pro_FS: 0,
        Pro_AC: 0,
        Pro_SP: 1,
        Pro_HP: 0,
        // Dimmer: this.form.Dimmer,
        // LEDNumber: this.form.LEDNumber,
        // CeilingHeight: this.form.cell_height,
        // SHNumber: this.form.SHNumber,
        // FreeLED: this.form.FreeLED,
      };

      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitbtn = true;
          // console.log(data);
          post("/api/api/QR_CustomerOrders/AddOrder", data).then((res) => {
            // if(res.data){}
            // console.log(res);
            if (res.data.code == 201) {
              this.$router.push("/ConfirmationSolar");
            } else {
              this.submitbtn = false;
              this.$message({
                type: "error",
                message: "Could not submit your information. Please Try Again.",
                duration: 2500,
              });
            }
          });
        } else {
          // this.$message({
          //   type: "warning",
          //   message: "The required item is incorrect, please check again!",
          //   duration: 2500,
          // });
          let msg = [];
          const h = this.$createElement;
          if (
            !this.form.route &&
            !this.form.locality &&
            !this.form.street_number &&
            !this.form.postal_code &&
            !this.form.address
          ) {
            delete obj.postal_code;
            delete obj.route;
            delete obj.locality;
            delete obj.street_number;
            for (let key in obj) {
              // console.log(obj[key]);
              msg.push(h("p", null, obj[key][0].message));
            }
          } else {
            for (let key in obj) {
              // console.log(obj[key]);
              msg.push(h("p", null, obj[key][0].message));
            }
          }
          this.$message({
            type: "warning",
            message: h("div", null, msg),
            duration: 2500,
          });
          if (
            !this.form.route ||
            !this.form.locality ||
            !this.form.street_number ||
            !this.form.postal_code
          ) {
            this.showandhide = true;
          }
        }
      });
    },

    getinfo(data) {
      this.form.address = data.address;
      if (!data.locality) {
        this.form.locality = "";
      } else if (data.locality !== "long_name") {
        this.form.locality = data.locality;
      }

      if (!data.route) {
        this.form.route = "";
      } else if (data.route !== "long_name") {
        this.form.route = data.route;
      }

      if (!data.street_number) {
        this.form.street_number = "";
      } else if (data.street_number !== "short_name") {
        this.form.street_number = data.street_number;
      }

      if (!data.postal_code) {
        this.form.postal_code = "";
      } else if (data.postal_code !== "short_name") {
        this.form.postal_code = data.postal_code;
      }
      if (!data.administrative_area_level_2) {
        this.form.City = "";
      } else if (data.administrative_area_level_2 !== "short_name") {
        this.form.City = data.administrative_area_level_2;
      }
      this.form.City = data.administrative_area_level_2;

      // console.log(data);
    },
    geolocate() {
      this.$refs.child.geolocate;
    },
    initAutocomplete() {
      this.$refs.child.initAutocomplete;
    },
    fillInAddress() {
      this.$refs.child.fillInAddress;
    },
  },
};
</script>

<style>
/* .tm{
  opacity:0;
} */
/* .el-button {
  font-size: 12px;
  padding: 12px 0;
} */
.el-message--warning .el-message__content {
  color: #e6a23c;
}
.el-message p {
  color: #e6a23c;
  font-size: 14px;
}
.float {
  float: left;
  top: 0;
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
.flex_left {
  display: flex;
  justify-content: left;
}
.iconparent {
  width: 82px;
}
.w_100 {
  width: 100%;
}
.imgbox {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.icon_div {
  width: 82px;
  height: 82px;
  /* display: flex;
  flex-direction: column;
  justify-items: center; */
  text-align: center;
  vertical-align: middle;
  border: 1px solid #dbdbdb;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  overflow: hidden;
}

.icon {
  vertical-align: middle;
  width: 100%;
  /* margin-top: 10%; */
  /* transform: translateY(20%); */

  /* height: 60px; */
}
.checkinfo {
  white-space: inherit;
  text-align: center;
  word-wrap: break-word;
}
.line_bot {
  font-weight: 600;
  text-decoration-line: underline;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.home_form_item {
  /* margin-left:200px; */
  position: relative;
  text-align: left;
  /* border-radius: 20px; */
  background-color: white;
  /* margin-bottom: 20px;
  padding-left: 20px; */
}
/* .home_form_item1 {
  position: relative;
  text-align: left;
  border-radius: 20px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
} */
.home_form_item1 .el-form-item {
  margin-bottom: 0;
}
.bor_t {
  border-top: 1px solid #ddd;
}
.bor_green {
  width: 50px;
  height: 4px;
  position: absolute;
  left: 8%;
  z-index: 1000;
  background-color: #4cd964;
  border-radius: 3px;
}
.home_form {
  /* width: 95%; */
  /* margin: 10px 10px; */
  border-radius: 10px;
  border: 1px solid #e8e9eb;
}
/* #all {
  width: 100%;
} */
.home1 {
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  /* padding-top: 10px; */
  background-color: #f8fafc;
  /* border: 1px solid #eee; */
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px rgb(206, 205, 205); */
}
.home_input {
  width: 100%;
  /* height: 70rpx; */
  border-top: 1px solid #eeeeee;
  border-radius: 10px;
  border: 0;
}
/* .home_form_item input,
.home_form_item1 input {
  background-color: rgb(245, 255, 255); 
   border-left: 0;
  border-right: 0;
  border-bottom: 0;
} */

.form_item {
  margin-left: 20px;
}
.language_rule_text {
  margin-left: 10px;
}

.el-radio__inner {
  border: 1px solid #4cd964;
}
.el-form-item {
  /* border:1px solid #ddd; */
  padding: 0 20px 30px 20px;
  margin: 0;
}
.el-input__inner {
  padding: 0px 0px 0px 5px;
}
.el-form-item__content {
  line-height: 20px;
}
.el-select {
  width: 100%;
}
.el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #888;
  font-size: 12px;
}

.el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888;
  font-size: 12px;
}

.el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #888;
  font-size: 12px;
}
.el-button--primary {
  width: 100%;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409eff;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #40ffd6;
}
.el-checkbox__inner,
.el-checkbox__inner.is-focus {
  border-color: #409eff;
}
/* 自定义提示框样式 */
.el-message {
  min-width: 50px !important;
  width: 75%;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  /* border-color: #0616f7; */
  position: fixed;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%);
  /* background-color: #edf2fc; */
  transition: opacity 0.3s, transform 0.4s, top 0.4s;
  padding: 15px 15px 15px 20px;
  display: flex;
  align-items: center;
}
.el-message--warning {
  border-color: #f8d7a9;
}
.el-message--error {
  border-color: #f73838;
}
.el-form-item__error {
  height: 60px;
  word-break: break-word;
}
.el-message--success {
  border-color: #4cd964;
}
.el-message-box {
  width: 50%;
}
.el-message-box__btns {
  display: flex;
  justify-content: space-around;
}
.el-checkbox {
  /* display: flex; */
  width: 100%;
  white-space: inherit;
  text-align: unset;

  word-break: break-word;
}
.el-checkbox__label {
  display: unset;
  width: 80px;
  font-size: 12px;
  margin-left: -24px;
}
.el-checkbox__input {
  /* display: none; */
  transform: translateY(-85px);
}
.el-select-dropdown__wrap .el-scrollbar__wrap {
  margin: 0;
}
</style>
